import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import {
  DEFAULT_WIDGET_OPTIONS,
  getAspectRatio,
  getBannerImages,
  getButtonVariantsData,
  getHeight,
  useGetAlignments,
  useUpgradeEditorBannerSelection,
  useWindowResize,
  // StoreName,
  WidgetProps,
  WithLink,
} from './utils';
import { useWidgetDndContextData } from '@/context/WidgetDndContext';
import { twMerge } from 'tailwind-merge';
import CustomizedButton from '../../../WidgetMaker/WidgetDnD/WIdgetPropertiesBasic/components/Button';
import contentAnimationStyle from '@/components/DesignSystem/AtomicDesignsUtilities/ContentAnimationStyles.module.scss';
import { btnActionHandler } from '@/components/DesignSystem/ModernTheme/utils/btnUtils';
import { renderMediaComponent } from '@/components/DesignSystem/ModernTheme/utils/common';
import { useSSRSelector } from '@/redux/ssrStore';
import { isMobileByUA } from '@/utils/operatingSystem';
import { IS_SERVER } from 'src/utils/checkRenderEnv';
import { BUTTON_SIZE_TYPE } from 'src/components/WidgetMaker/utils/buttonConstant';

export const CarouselWidget = (props: WidgetProps) => {
  const sliderRef = useRef(null);
  const containerRef = useRef<HTMLDivElement>();
  const userAgent = useSSRSelector((state) => state.commonReducer.userAgent);
  const isInitialRequestByMobile = isMobileByUA(userAgent);
  const isDesktop = IS_SERVER
    ? !isInitialRequestByMobile
    : containerRef.current?.clientWidth > 720;

  const {
    // hasAdminAccess,
    widgetContextState: { widgets, showPreview },
  } = useWidgetDndContextData();

  const activeWidget = widgets[(props as any).id];
  const activeWidgetProps = activeWidget?.props;

  const widgetOptions = {
    ...DEFAULT_WIDGET_OPTIONS,
    ...props.widgetOptions,
    ...(activeWidgetProps || {}),
  };
  const isNewCarousel = widgetOptions.forNewCarousel;

  const [activeSlide2, setActiveSlide2] = useState(0);
  const [height, setHeight] = useState(getHeight(widgetOptions, '100vh', isNewCarousel));
  const [aspectRatio, setAspectRatio] = useState(widgetOptions?.aspectRatio);

  const storeInfo = useSSRSelector((state) => state.storeReducer.store);

  useWindowResize({
    dependencies: [showPreview],
    executor: adjustDimensions,
    widgetOptions,
    props,
  });

  const { handleNextClick, handlePrevClick, allSliderSettings } =
    useUpgradeEditorBannerSelection({
      activeSlide2,
      setActiveSlide2,
      id: (props as any).id,
      sliderRef,
      widgetOptions,
    });

  const { getButtonAlignment, getContentAlignment, getContentPosition } =
    useGetAlignments({
      isDesktop,
      widgetOptions,
    });

  const images = getBannerImages({ props, activeWidgetProps, containerRef });

  function adjustDimensions() {
    setHeight(getHeight(widgetOptions, containerRef.current?.clientWidth, isNewCarousel));
    setAspectRatio(getAspectRatio(widgetOptions, containerRef.current?.clientWidth));
  }

  if (!images?.length) {
    return null;
  }

  return (
    <div
      className={`carousel-widget-slider slider-container tw-relative tw-bg-opacity-20`}
      ref={containerRef}
      id="carousel-widget-slider"
      style={
        isNewCarousel && {
          backgroundColor: storeInfo?.theme?.colors?.primary_color || '#000',
        }
      }
    >
      <div style={isNewCarousel ? { height } : { aspectRatio }}>
        {isDesktop && images.length > 1 && (
          <div className="slider-arrows">
            <img
              alt="Left Button"
              src="/assets/icons/arrowLeftIconCircleDark.png"
              className={`arrow-btns absolute left-1 img h2 mr2 pointer`}
              onClick={handlePrevClick}
            />
            <img
              alt="Right Button"
              src="/assets/icons/arrowRightIconCircleDark.png"
              className={`arrow-btns absolute right-1 img h2 mr2 pointer`}
              onClick={handleNextClick}
            />
          </div>
        )}
        <Slider
          // className="carousel-widget-slider"
          {...allSliderSettings}
          ref={(node) => (sliderRef.current = node)}
          className="tw-flex tw-items-center tw-justify-center"
        >
          {images.map((image, index) => {
            const isCurrentSlideActive = activeSlide2 === index;
            const imageUrl = image.image_url || image.src;

            return (
              <div
                key={index}
                style={{
                  backgroundColor: storeInfo?.theme?.colors?.primary_color || '#000',
                }}
                className={`tw-relative  tw-bg-opacity-${image?.textContent?.overlayOpacity || 20}`}
                onClick={() => {
                  if (image?.actions?.handler) {
                    btnActionHandler(image?.actions);
                  }
                }}
              >
                <WithLink {...props} widgetOptions={widgetOptions} index={index}>
                  <div
                    className={twMerge(
                      'image-container ',
                      'tw-opacity-0 ',
                      isCurrentSlideActive
                        ? contentAnimationStyle.widgetContentFadeInZoomInWrapper
                        : ''
                    )}
                  >
                    {imageUrl &&
                      (image.is_video ? (
                        renderMediaComponent({
                          type: 'video',
                          autoplay: true,
                          hideControls: true,
                          content: [
                            {
                              url: imageUrl,
                            },
                          ],
                        })
                      ) : (
                        <img
                          src={imageUrl}
                          alt={image?.textContent?.bannerMetaText || ''}
                          // className={isNewCarousel && 'tw-h-[100%] tw-object-cover'}
                        />
                      ))}
                    {/* {index === 0 && (
                      <StoreName
                        {...props}
                        widgetOptions={widgetOptions}
                        store={props.store}
                      />
                    )} */}
                  </div>
                </WithLink>
                <div
                  className={`tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-z-[1]`}
                  style={{
                    background: `rgba(0,0,0, ${image?.textContent?.overlayOpacity / 100})`,
                  }}
                ></div>
                <div
                  className={`tw-absolute tw-top-1/2 tw-z-30 tw-w-[100%] tw--translate-y-1/2 tw-p-40 xl:tw-max-w-[1080px] ${getContentAlignment()}`}
                  style={{
                    padding: ` 40px ${isDesktop ? '92px' : '40px'} 40px ${isDesktop ? '92px' : '40px'}`,
                  }}
                >
                  {image?.textContent?.isShow && isCurrentSlideActive && (
                    <div
                      className={twMerge(
                        'tw-z-[999]  ',
                        isCurrentSlideActive &&
                          contentAnimationStyle[
                            widgetOptions.contentAnimationConfig?.className
                          ]
                      )}
                    >
                      {image?.textContent?.headline ? (
                        <h1
                          style={{
                            textAlign: getContentPosition(),
                            fontSize: isDesktop ? 40 : 28,
                          }}
                          className={`tw-my-0 tw-font-light tw-text-white tw-text-${getContentPosition()} tw-text-[28px] md:tw-text-[40px] `}
                        >
                          {image?.textContent?.headline}
                        </h1>
                      ) : null}
                      {image?.textContent?.description ? (
                        <p
                          style={{ textAlign: getContentPosition() }}
                          className={`tw-mb-0 tw-mt-16 tw-text-16 tw-font-light tw-leading-[23px] tw-text-white tw-text-${getContentPosition()}`}
                        >
                          {image?.textContent?.description}
                        </p>
                      ) : null}
                    </div>
                  )}
                  {image?.buttons?.length && isCurrentSlideActive ? (
                    <div
                      className={twMerge(
                        `tw-mt-[36px] tw-flex tw-w-full tw-flex-col md:tw-flex-row ${getButtonAlignment('flex')}`,
                        isCurrentSlideActive &&
                          contentAnimationStyle[
                            widgetOptions.contentAnimationConfig?.className
                          ]
                      )}
                      style={{
                        flexDirection: isDesktop ? 'row' : 'column',
                      }}
                    >
                      {image.buttons?.map((buttonData, btnIndex) => {
                        const buttonVariant = getButtonVariantsData(buttonData);
                        const selectedButtonType = buttonVariant[buttonData.type];

                        return buttonData.isShow ? (
                          <CustomizedButton
                            key={btnIndex}
                            size={BUTTON_SIZE_TYPE.LARGE}
                            btnType={buttonData.type}
                            buttonData={buttonData}
                            selectedButtonData={selectedButtonType}
                            variant={selectedButtonType.variant}
                            onClick={() =>
                              btnActionHandler(buttonData?.actions, () => {})
                            }
                            className={twMerge(
                              `tw-rounded-none !tw-text-[16px] !tw-font-normal
                               [&>p]:!tw-text-ellipsis [&>p]:!tw-whitespace-nowrap
                                ${
                                  btnIndex
                                    ? isDesktop
                                      ? ' !tw-ml-[25px] sm:!tw-ml-0 sm:!tw-mt-[25px] '
                                      : ' !tw-mt-[14px] sm:!tw-ml-0 sm:!tw-mt-[14px] '
                                    : ''
                                }
                                ${!isDesktop ? '!tw-w-[100%]' : ''}
                                `
                            )}
                          >
                            {buttonData?.text}
                          </CustomizedButton>
                        ) : null;
                      })}
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default CarouselWidget;
